import React from 'react'
import SourceBtn from '../source_btn'
import img from '../../../static/site.png'
import css from '../../styles/project.module.scss'

// project req:
// title, date, description, src code (all of this can be passed in as children: React.ReactNode)
export default function P_site() {

    return (
        <div className={`${css.pContainer} ${css.firstP}`}>
            <div className={css.lContainer}>
                <img src={img} alt="A screenshot of my website"/>
            </div>
            <div className={css.rContainer}>
                <h2>Personal Website</h2>
                <h3>2020-2021</h3>
                <p>This personal website is my current, most recent project that I've done 
                   so far. Besides featuring my online portfolio, it also features a series of 
                   blog posts that I wrote to archive and share what I've learned in my career.
                </p>
                <p>
                   As this website is built with GatsbyJS, I gained valuable experiences in programming with 
                   React components, building interactive and animated web UIs with Javascript and Sass, and 
                   creating blog posts programmatically from Markdown documents with Gatsby's source and transform plugins.
                </p>
                <SourceBtn name="View Source for Canvas" src="https://github.com/HarryL5004/Site_Canvas" color="dark" />
            </div>
        </div>
    )
}