import React from 'react'
import Bio from './bio'
import P_site from './projects/p_site'
import P_aurum from './projects/p_aurum'
import P_scrape from './projects/p_scrape'
import P_app from './projects/p_bus_app'
import P_rpsls from './projects/p_rpsls'
import css from '../styles/contents.module.scss'
import firebase from 'gatsby-plugin-firebase'

// export default function Contents(): JSX.Element {
export default class Contents extends React.Component {
    state: {hide: boolean}

    constructor(props) {
        super(props);
        this.state = {hide: false};
    }

    toggleProjects() {
        // if (typeof window !== 'undefined')
        //     firebase.analytics().logEvent('toggled_other_projects', {hidden: !this.state.hide});
        this.setState({hide: !this.state.hide});
    }

    render() {
        const otherProjects = (
            <div className={css.mContainer}> {/*Small container from here*/}
                <P_scrape /> {/*Webscrape Project from ART*/}
                <P_rpsls /> {/*Java RPSLS project from CS342*/}
                <P_app /> {/*CTA Bus App*/}
            </div>
        );

        return (
            <div className={css.container}>
                <Bio />
                <P_site /> {/* This Website */}
                <P_aurum /> {/* Project Aurum*/}
                <button onClick={() => this.toggleProjects()} className={css.collaspible}>
                    Other Projects 
                    <span>
                        {
                            this.state.hide ? '\u25BC' : '\u25B2'
                        }
                    </span>
                </button>
                {this.state.hide ? '' : otherProjects}
            </div>
        )
    }
}