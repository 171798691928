import React from 'react'
import SourceBtn from '../source_btn'
import img from '../../../static/rpsls.png'
import css from '../../styles/mproject.module.scss'
import firebase from 'gatsby-plugin-firebase'

export default function P_RPSLS(): JSX.Element {
    // let logged: boolean = false;
    const logHover = (e) => {
        // e.stopPropagation();
        // if (typeof window !== 'undefined' && !logged) {
        //     firebase.analytics().logEvent('hovered_other_project', {project_name: "RPSLS"});
        //     logged = !logged;
        // }
    }

    return (
        <div className={css.pContainer} onMouseOverCapture={logHover}>
            <i className={css.pin}></i>
            <div className={css.space}>
                <div className={css.cuboid}>
                    <div className={css.front}>
                        <img src={img} alt="A screenshot of the rock-paper-scissors-lizard-spock game" />
                    </div>
                    <div className={css.side}>
                        <SourceBtn src="https://github.com/HarryL5004/RPSLS" color="light" />
                    </div>
                </div>            
            </div>
            <div className={css.descDiv}>
                <h2>RPSLS</h2>
                <h3>2019</h3>
                <p>
                    This is a simple, classic rock-paper-scissors game written in Java with 
                    'lizard' and 'spock' as additional choices.    
                </p>
                <p>
                    To start the game, two clients are required to connect to the server 
                    at the user specified port. The clients will then battle it out in a best of five.
                </p>
                <p>
                   Although this project is relatively simple, I gained an understanding of the fundamentals of 
                   multithreading and methods to avoid deadlocks in Java, which are crucial for 
                   concurrent and parallel computing.
                </p>
                <div className={css.frontBtn}>
                    <SourceBtn src="https://github.com/HarryL5004/RPSLS" color="light" />
                </div>
            </div>
        </div>
    )
}