import React from 'react'
import SourceBtn from '../source_btn'
import img from '../../../static/aurum.jpg'
import css from '../../styles/project.module.scss'

export default function P_aurum() {
    return (
        <div className={`${css.pContainer} ${css.secondP}`}>
            <div className={css.lContainer}>
                <h2>Project Aurum</h2>
                <h3>Role: General Programmer</h3>
                <h3>2019-2020</h3>
                <p>
                    Project Aurum is a project started by the blockchain student interest group
                    that aims to learn about blockchain technology and its implementation in 
                    cryptocurrency by creating a pseudo cryptocurrency named Aurum for group members to use.
                </p>
                <p>
                    In addition to learning about blockchain, participating in such a large project
                    gave me the chance to experience working as a team and realize the importance of 
                    allocating work to people with different capabilities, as well as 
                    practicing various common programming practices such as test driven development
                    and code reviews.
                </p>
                    <SourceBtn src="https://github.com/SIGBlockchain/project_aurum" color="light" />
            </div>
            <div className={css.rContainer}>
                <img src={img} alt="The logo for the blockchain project"/>
            </div>
        </div>
    )
}