import React from 'react'
import SourceBtn from '../source_btn'
import img from '../../../static/bus_app.png'
import css from '../../styles/mproject.module.scss'
import firebase from 'gatsby-plugin-firebase'

export default function P_bus_app(): JSX.Element {
    // let logged: boolean = false;
    function logHover() {
        // if (typeof window !== 'undefined' && !logged) {
        //     firebase.analytics().logEvent('hovered_other_project', {project_name: "Bus App"});
        //     logged = !logged;
        // }
    }

    return (
        <div className={css.pContainer} onMouseOverCapture={logHover}>
            <i className={css.pin}></i>
            <div className={css.space}>
                <div className={css.cuboid}>
                    <div className={css.front}>
                        <img src={img} alt="A screenshot taken with a smartphone of my android CTA bus tracker app" />
                    </div>
                    <div className={css.side}>
                        <SourceBtn src="https://github.com/HarryL5004/CTA_BusTracker" color="light" />
                    </div>
                </div>            
            </div>
            <div className={css.descDiv}>
                <h2>CTA Bus Tracker</h2>
                <h3>2019</h3>
                <p>
                    CTA Bus Tracker is an android application written in Java to 
                    retrieve CTA buses' arrival times using CTA's bus tracker web API.
                </p>
                <p>
                   I started this project with the mindset of developing a more
                   personal and convenient bus tracker application for my android smartphone, and 
                   improving my understanding in android application development including 
                   the methods of calling web APIs in an android application.
                </p>
                <div className={css.frontBtn}>
                    <SourceBtn src="https://github.com/HarryL5004/CTA_BusTracker" color="light" />
                </div>
            </div>
        </div>
    )
}