import React from 'react'
import SourceBtn from '../source_btn'
import img from '../../../static/derive.png'
import css from '../../styles/mproject.module.scss'
import firebase from 'gatsby-plugin-firebase'

// type Project_propTypes = {title: string, date: string, src:string, children: React.ReactNode} //children: React.ReactNode
export default function P_scrape() {
    // let logged: boolean = false;
    const logHover = (e) => {
        // if (typeof window !== 'undefined' && !logged) {
        //     firebase.analytics().logEvent('hovered_other_project', {project_name: "Web Scrape"});
        //     logged = !logged;
        // }
    }

    return (
        <div className={css.pContainer} onMouseOverCapture={logHover}>
            <i className={css.pin}></i>
            <div className={css.space}>
                <div className={css.cuboid}>
                    <div className={css.front}>
                        <img src={img} alt="A screenshot of my dérive website"/>
                    </div>
                    <div className={css.side}>
                        <SourceBtn src="https://github.com/HarryL5004/151Derive" color="light" />
                    </div>
                </div>            
            </div>
            <div className={css.descDiv}>
                <h2>Dérive - Web scraping Website</h2>
                <h3>2020</h3>
                <p>If you are wondering what <em>dérive</em> means, <em>dérive</em> is a french word 
                    which translates to <b>drift</b> or <b>drifting</b> in English.
                </p>
                <p>
                    By "drifting" in the space known as the Internet, this website
                    web scrapes text from random websites found via Google Search that are relevant to the
                    topic selected by the user to generate a poem with those text automatically.
                </p>
                <p>
                    This website is built using Handlebars templating engine alongside with Express.js 
                    to handle general routing and to serve visitors the generated poems.
                </p>
                <div className={css.frontBtn}>
                    <SourceBtn src="https://github.com/HarryL5004/151Derive" color="light" />
                </div>
            </div>
        </div>
    )
}