import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Intro from '../components/intro'
import Contents from '../components/contents'
import firebase from "gatsby-plugin-firebase";

export default function MainPage(): JSX.Element {
    // alert("add event listener here")
    // if (typeof window !== 'undefined')
    //     firebase.analytics().logEvent("visited_Bio");

    return (
        <Layout>
            <SEO title="Harry Leung - Bio" 
                 desc="Harry Leung's personal website" />
            <Intro />
            <Contents />
        </Layout>
    )
}