import React from 'react';
import {loadableP5 as P5Wrapper} from './loadable'
import Background from './background';

export default function Canvas({children}) {
    return (
        <P5Wrapper sketch={Background}>
            {children}
        </P5Wrapper>
    )
}