import React from 'react'
import css from '../styles/bio.module.scss'

export default function Bio() {
    return (
        <div className={css.container}>
            <div className={css.bioContainer}>
                <p>
                    <span>A little bit about myself, </span>
                    I am a software engineer from Chicago that enjoys coming up with computerized 
                    solutions to solve the problems I regularly face. Sounds familiar? Well, I'd figured that most 
                    software engineers' or programmers' website have more or less the same generic introduction anyways. 
                    Perhaps what makes me stand out a bit more is that I am not afraid of admitting that I can, in fact, make mistakes. 
                    And I am not afraid of it so long as I can continue to learn from it (and from my peers) 
                    to come up with better solutions to my problems.
                </p>
                <p>
                    Check out some of the projects I've worked on below!
                </p>
            </div>
            {/* <p className={css.photoCredit}>
                Photo of Chicago high-rises by Chait Goli from Pexels
            </p> */}
        </div>
    )
}