import React, {useState} from 'react'
import css from '../styles/intro.module.scss'
import Profile_Icon from '../components/profile_icon'
import Canvas from './p5/p5wrapper'
import firebase from "gatsby-plugin-firebase";

export default function Intro() {
    const logEvent = () => {
        // if (typeof window !== 'undefined')
        //     firebase.analytics().logEvent('clicked_main_canvas');
    }

    const closeNotice = () => {
        document.getElementById('firefox_notice').style.display = 'none';
    }

    const [canvasShown, toggleCanvas] = useState(false);
    let firefox = false;
    let firefoxStyle;
    if (typeof InstallTrigger !== 'undefined') {
        firefox = true;
        firefoxStyle = {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            height: 'min-content',
            padding: '1em',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#424242',
            boxSizing: 'border-box',
            zIndex: '4',
        };
    }

    return (
        <div className={css.container} id="intro_container" onClick={logEvent}>
            {firefox && !canvasShown ? 
                <div>
                    <Profile_Icon />
                    <div className={css.greetings} id='greetings'>
                        <p>Hey there!</p>
                        <p>I'm Harry Leung.</p>
                    </div>
                </div>
                :
                <Canvas>
                {/* <div> */}
                {/* ^- for testing purposes -^ */}
                    {/*default values for the img size are replaced in the corresponding CSS module*/}
                    <Profile_Icon />
                    <div className={css.greetings} id='greetings'>
                        <p>Hey there!</p>
                        <p>I'm Harry Leung.</p>
                    </div>
                {/* v- for testing purposes -v */}
                {/* </div> */}
                </Canvas>
            }

            <noscript className={css.noScript}>
                <Profile_Icon />
                <div className={css.greetings} id='greetings'>
                    <p>Hey there!</p>
                    <p>I'm Harry Leung.</p>
                </div>
            </noscript>
            {firefox ? 
                <div id="firefox_notice" style={firefoxStyle}>
                    <p style={{maxWidth: '1000px', fontSize: '10px', margin: '0', color: '#ffffff'}}>
                        Hello Firefox users. Due to this background canvas' poor performance when rendered on Firefox, 
                        it is currently not rendered for best user experience. If you wish to view this background canvas anyways, click 
                        the <strong>Toggle Canvas</strong> button below. 
                        Alternatively, you can also use other browsers such as Google Chrome 
                        and Microsoft Edge, or click the <strong>View Source for Canvas</strong> button located further in the page.
                    </p>
                    <div style={{display: "flex", marginTop: '1em', alignItems: 'center'}}>
                        <button className="toggleCanvas" onClick={() => toggleCanvas(!canvasShown)}>
                            Toggle Canvas
                        </button>
                        <span className="material-icons ack-btn" onClick={closeNotice}>
                            &#xe14c;
                        </span>
                    </div>
                </div>
                : ""
            }
        </div>
    )
}