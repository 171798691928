import * as p5 from "p5";

// Background for intro component with P5.js
export default function Background(sketch) {
  const roses: Array<Rose> = [];
  let rose1Canvas: p5.Graphics, 
      rose2Canvas: p5.Graphics, 
      rose3Canvas: p5.Graphics; // 4/2, 5/3, 7/3
  let slider: p5.Element;
  let sliderVisible: boolean = false;
  let numOfRoses = 7;
  let windowDim: p5.Vector;
  
  class Rose {
    pos: p5.Vector;
    vel: p5.Vector;
    radians: number;
    rotateDir: number;
    initAngle: number;
    amp: number;
    type: p5.Graphics;

    constructor(roseType: p5.Graphics) {
      this.pos = sketch.createVector(sketch.random(sketch.width));
      this.vel = sketch.createVector(1, sketch.random(1, 4));
      this.radians = 0;
      this.rotateDir = sketch.random(0, 1) < 0.5 ? 1 : -1;
      this.initAngle = sketch.random(0, 2 * sketch.PI);
      this.amp = sketch.random(5, 90);
      this.type = roseType;
    }
    update() {
      this.pos.y += this.vel.y;
      this.radians += sketch.PI / 180 * this.rotateDir;
      this.initAngle += sketch.PI / 60;
    }
    render() {
      sketch.push()
      sketch.imageMode(sketch.CENTER)
      sketch.translate(this.pos.x + sketch.sin(this.initAngle)*this.amp, this.pos.y)
      sketch.rotate(this.radians)
      sketch.image(this.type, 0, 0);
      sketch.pop();
    }
  }

  const drawRose = (n: number, d: number, 
                    strokeColor: string, fillColor: string) => {
    let canvas = sketch.createGraphics(sketch.width / 2, sketch.height / 2);
    canvas.push();
    canvas.translate(sketch.width / 4, sketch.height / 4)
    canvas.beginShape();
    canvas.stroke(strokeColor);
    canvas.strokeWeight(3)
    canvas.fill(fillColor)
    for (let i = 0; i < sketch.TWO_PI * d; i += 0.1) {
      let r = 20 * sketch.cos(n / d * i);
      let x = r * sketch.cos(i)
      let y = r * sketch.sin(i);
      canvas.vertex(x, y)
    }
    canvas.endShape(sketch.CLOSE);
    canvas.pop()
    return canvas
  }
  
  // randomly generate a random rose and store it in an array
  const randRose = () => {
    let randNum = sketch.randomGaussian(0.5);
    if (randNum <= 0.33)
      roses.push(new Rose(rose1Canvas))
    else if (randNum <= 0.66)
      roses.push(new Rose(rose2Canvas))
    else
      roses.push(new Rose(rose3Canvas))
  }  
    
  // lerp the canvas' background color based on the argument
  const setBgColor = (dif: number) => {
    let from = sketch.color('#e3f2fd');
    let to = sketch.color('#000051');
    sketch.background(sketch.lerpColor(from, to, 
        sketch.norm(dif, 0, sketch.height/2)))
  } 

  // # of inital roses is already defined - search for 'numOfRoses'
  sketch.setup = () => {
    // sketch.windowWidth and sketch.windowHeight do not update properly when canvas 
    // wrapper component is toggled on/off
    let mainCanvas = sketch.createCanvas(window.innerWidth, window.innerHeight);
    mainCanvas.id("intro_canvas");

    sketch.background('#e3f2fd');

    rose1Canvas = drawRose(4, 2, '#ba2d65', '#ffcdd2');
    rose2Canvas = drawRose(5, 3, '#e53935', '#fae');
    rose3Canvas = drawRose(7, 3, '#fae', '#fff176');
    slider = sketch.createSlider(0, 500, numOfRoses, 1);
    slider.position(10, 60);
    slider.size(sketch.AUTO);
    slider.hide();

    document.getElementById('intro_container').addEventListener('click', () => {
      if (sliderVisible)
        slider.hide();
      else
        slider.show();
      sliderVisible = !sliderVisible;
    })

    windowDim = sketch.createVector(window.innerWidth, window.innerHeight);
    sketch.resizeCanvas(window.innerWidth, window.innerHeight);
  }

  sketch.draw = () => {
    if (!document.getElementById('intro_canvas')) {
      sketch.noLoop();
    }

    if (roses.length < slider.value()) {
      randRose()
    }

    if (windowDim.x !== window.innerWidth || windowDim.y !== window.innerHeight) {
      windowDim = sketch.createVector(window.innerWidth, window.innerHeight);
      sketch.resizeCanvas(window.innerWidth, window.innerHeight);
    }
    
    // update canvas' background color and text
    let canavsDif = document.getElementById('intro_canvas').getBoundingClientRect().y * -1;
    setBgColor(canavsDif);
    sketch.selectAll('#intro_container #greetings p').forEach(p => {
      p.style('color', sketch.lerpColor(
        sketch.color('#212121'),
        sketch.color('#ffffff'), 
        sketch.norm(canavsDif, 0, sketch.height/2)).toString());
    });

    // update roses' position
    for (let rose of roses) {
      rose.update();
      rose.render();
      // 20: rose's radius
      if (rose.pos.y >= sketch.height + 20|| rose.pos.x >= sketch.width + 20|| 
          roses.length > slider.value()) {
        roses.splice(roses.indexOf(rose), 1);
      }
    }
  }
}